var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    staticClass: "gx-table-responsive",
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.teams,
      loading: _vm.dataLoading
    },
    scopedSlots: _vm._u([
      {
        key: "operations",
        fn: function(text, row) {
          return _c(
            "div",
            { staticClass: "gx-text-right" },
            [
              _c(
                "a-button",
                {
                  staticClass: "gx-btn-cyan",
                  staticStyle: { "margin-left": "5px" },
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.emitEdit(row.user_id)
                    }
                  }
                },
                [_vm._v("\n      Manage\n    ")]
              ),
              row && row.role !== "Admin"
                ? _c(
                    "a-button",
                    {
                      staticClass: "gx-btn-danger",
                      staticStyle: { "margin-left": "5px" },
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.removeAdmin(row.user_id)
                        }
                      }
                    },
                    [_vm._v("\n      Remove\n    ")]
                  )
                : _vm._e()
            ],
            1
          )
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }